import { useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { formatPrice } from '@helpers/formatPrice';

import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { CellContent } from '@pages/Admin/BillingAccountsPage/components/Content/styles/CellContent';
import { PlusBalanceIcon } from '@pages/Admin/BillingAccountsPage/components/Content/styles/PlusBalanceIcon';
import { PencilIcon } from '@pages/Admin/BillingAccountsPage/components/Content/styles/PencilIcon';

import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Button } from '@components/form-elements/buttons/Button';
import { CustomTable } from '@components/Table';
import { DisclosureSection } from '@components/DisclosureSection';

import { usePageContext } from '../../providers/PageProvider';
import { Wrapper } from './styles/Wrapper';
import { NameSectionViewOnly } from './components/NameSection/NameSectionViewOnly';
import { WorkspacesTable } from './components/WorkspacesTable';
import { TeamsList } from './components/TeamsList';
import { RepositoriesTable } from './components/RepositoriesTable';
import { routes } from '@routes';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

export const Content = () => {
  const { me } = useAuthContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  const { organization, isLoading, openBalanceModal, openRateModal, openRatesModal, recalculateBalances } =
    usePageContext();
  const [baDisclosureOpen, setBADisclosureOpen] = useState<boolean>(true);

  const tableHead = [
    {
      headComponent: () => <td>Billing Account Name</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          <StyledLink
            to={routes.adminBillingAccountDetails.make(data?.id)}
            className="font-medium text-queen-blue hover:text-primary"
          >
            {data.name}
          </StyledLink>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Balance</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              {formatCentsPrice(
                (data.MainBalance?.currentBalance ?? 0) + (data.FreeCreditBalance?.currentBalance ?? 0),
              )}
              {isVsBilling ? <PlusBalanceIcon onClick={() => openBalanceModal(data.id)} /> : null}
            </div>
            <div className="flex flex-row">
              <small className="mr-2">Main: {formatCentsPrice(data.MainBalance?.currentBalance ?? 0)}</small>
              <small>Free Credit: {formatCentsPrice(data.FreeCreditBalance?.currentBalance ?? 0)}</small>
            </div>
          </div>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Tier Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {data.fixedRate ? 'Fixed: ' : ''}
          {formatPrice(data.tierRate ?? 0)}/hr
          {isVsBilling ? <PencilIcon onClick={() => openRateModal(data.id)} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Post Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {['DISALLOWED'].includes(data.billingConfiguration.postProcessingRateType) ? 'Disallowed' : null}
          {['TIER_RATE'].includes(data.billingConfiguration.postProcessingRateType) ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.postProcessingRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.postProcessingRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data?.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Real-Time Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {['DISALLOWED'].includes(data.billingConfiguration.realTimeRateType) ? 'Disallowed' : null}
          {['TIER_RATE'].includes(data.billingConfiguration.realTimeRateType) ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.realTimeRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.realTimeRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data?.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Partial Unlock Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {['DISALLOWED'].includes(data.billingConfiguration.partialUnlockRateType) ? 'Disallowed' : null}
          {['TIER_RATE'].includes(data.billingConfiguration.partialUnlockRateType) ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.partialUnlockRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.partialUnlockRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Full Unlock Rate</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          {['DISALLOWED'].includes(data.billingConfiguration.fullUnlockRateType) ? 'Disallowed' : null}
          {['TIER_RATE'].includes(data.billingConfiguration.fullUnlockRateType) ? 'Tier Rate' : null}
          {!['DISALLOWED', 'TIER_RATE'].includes(data.billingConfiguration.fullUnlockRateType) ? (
            <>{formatPrice(Number(data.billingConfiguration.fullUnlockRate ?? 0))}</>
          ) : null}
          {isVsBilling ? <PencilIcon onClick={() => openRatesModal(data.id ?? '')} /> : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Recalculate Balance</td>,
      render: (data: TBillingAccount) => (
        <CellContent>
          <Button variant="outlined" onClick={() => recalculateBalances(data.id)}>
            Recalculate
          </Button>
        </CellContent>
      ),
    },
  ];

  const billingAccounts = organization?.BillingAccount ? [organization?.BillingAccount] : [];

  return (
    <>
      <LinearProgressLoader active={isLoading} />
      {organization ? (
        <Wrapper>
          <NameSectionViewOnly organization={organization} />
          <WorkspacesTable workspaces={organization.workspaces} />
          <RepositoriesTable repositories={organization.repositories} />
          <TeamsList teams={organization.Teams} />
          {isVsBilling ? (
            <DisclosureSection
              title={'Billing Accounts'}
              open={baDisclosureOpen}
              setOpenState={setBADisclosureOpen}
              loading={false}
            >
              <CustomTable head={tableHead} data={billingAccounts} />
            </DisclosureSection>
          ) : null}
        </Wrapper>
      ) : null}
    </>
  );
};
