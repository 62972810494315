import styled from 'styled-components';

import { formatPrice } from '@helpers/formatPrice';
import { TBillingConfiguration } from 'app/types/entities/TBillingConfiguration';

const Wrapper = styled.div`
  width: auto;
  min-width: 210px;
  min-height: 220px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    padding: 20px 20px 7px;
  }

  .title {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 0 0 5px;
  }

  .price {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #40608f;
    padding: 0 0 8px;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
  }
`;

const getRateTypeSuffix = (rateType: string) => {
  if (rateType === 'PER_HOUR') {
    return `/hr (fixed)`;
  }
  if (rateType === 'TIER_RATE') {
    return `/hr`;
  }
  if (rateType === 'FLAT_FEE') {
    return '';
  }
};

const formatRate = (v: number | string, rateType: string, label: string) => {
  const numericPrice = typeof v === 'string' ? Number(v) : v;
  const disallowed = rateType === 'DISALLOWED';
  const prefix = disallowed ? '✗' : '✓';
  const formattedPrice = disallowed
    ? 'N/A'
    : !numericPrice
      ? 'Free'
      : numericPrice >= 100
        ? `$${numericPrice}`
        : `$${numericPrice.toFixed(2)}`;
  const suffix = disallowed || !numericPrice ? '' : getRateTypeSuffix(rateType);
  return `${prefix} ${label}: ${formattedPrice}${suffix}`;
};

type TProps = {
  className?: string;
  reload: () => void;
  billingConfiguration: TBillingConfiguration;
};

export const CurrentConfigurationCard = ({ className = '', billingConfiguration }: TProps) => {
  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="title">Tier Rate</h2>
        <div className="price">{formatPrice(Number(billingConfiguration.postProcessingRate ?? 0))}/hr</div>
        <h3>per audio hour transcription</h3>
        <h3 className={billingConfiguration.realTimeRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(billingConfiguration.realTimeRate, billingConfiguration.realTimeRateType, 'Real-time ASR')}
        </h3>
        <h3 className={billingConfiguration.partialUnlockRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(
            billingConfiguration.partialUnlockRate,
            billingConfiguration.partialUnlockRateType,
            'Reporter Unlock',
          )}
        </h3>
        <h3 className={billingConfiguration.fullUnlockRateType === 'DISALLOWED' ? 'line-through' : ''}>
          {formatRate(billingConfiguration.fullUnlockRate, billingConfiguration.fullUnlockRateType, 'Full Unlock')}
        </h3>
      </div>
    </Wrapper>
  );
};
