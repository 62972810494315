import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #f8fafb;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 590px;
  position: relative;
  padding: 30px;
  padding-bottom: 0;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;

  .cardTitle {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  & .cardContainer {
    display: flex;
    min-width: 100%;
    width: max-content;
    grid-gap: 20px;
    height: 315px;
    padding: 18px 0 0;
  }

  & .scrollContainer {
    overflow-x: auto;
    height: max-content;
    padding: 0 0 15px;
    margin: 0 0 20px;
    border-bottom: 1px solid #d5ddf2;
  }

  .buttonModalContainer {
    display: flex;
    position: sticky;
    bottom: 0;
    left: 0;
    background: #f8fafb;
    padding: 20px 0 30px;
    justify-content: space-between;

    width: 100%;

    & button {
      width: max-content;
    }
  }
`;
