import React, { useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { useModalContext } from '@providers/ModalProvider';
import { updateASOnePlan } from '@queries/admin/asone/updateASOnePlan';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Switch } from '@components/form-elements/Switch';
import { Select } from '@components/form-elements/Select';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { EErrorMessages } from '@constants/errorMessages';
import { H30pxInput } from '@components/form-elements/Input/variations/H30pxInput';
import { H30pxCurrencyInput } from '@components/form-elements/CurrencyInput/variations/H30pxCurrencyInput';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 405px;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #858dbd;
    margin: 0 0 30px;
    text-align: center;
  }

  .flex-row {
    margin: 0 0 20px;

    .input-wrapper,
    .switch-wrapper {
      max-width: 166px;
    }

    .form-select {
      min-width: 166px;
    }

    .input-wrapper {
      .input,
      .textarea {
        margin: 0;
      }
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .textarea {
      margin: 0 0 20px;
    }
  }

  .switch-wrapper {
    display: flex;
    margin: 20px 0 0;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }
  }

  .public {
    display: flex;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  plan: TSubscriptionProduct;
  onCancel: () => void;
  onSuccess: () => void;
};

export const EditASOnePlanModal = ({ plan: initialPlan, onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [plan, setPlan] = useState({
    ...initialPlan,
    description: initialPlan.description.split('<newLine>').join('\n'),
    rsPro: initialPlan.rsPro ?? false,

    asrRate: initialPlan.DefaultBillingConfiguration?.realTimeRate ?? '0',
    postRate: initialPlan.DefaultBillingConfiguration?.postProcessingRate ?? '0',
    reporterUnlockRate: initialPlan.DefaultBillingConfiguration?.partialUnlockRate ?? '0',
    fullUnlockRate: initialPlan.DefaultBillingConfiguration?.fullUnlockRate ?? '0',

    asrRateType: initialPlan.DefaultBillingConfiguration?.realTimeRateType || 'FLAT_FEE',
    postRateType: initialPlan.DefaultBillingConfiguration?.postProcessingRateType || 'FLAT_FEE',
    reporterUnlockRateType: initialPlan.DefaultBillingConfiguration?.partialUnlockRateType || 'FLAT_FEE',
    fullUnlockRateType: initialPlan.DefaultBillingConfiguration?.fullUnlockRateType || 'FLAT_FEE',
    defaultBillingConfigurationId: initialPlan.defaultBillingConfigurationId || undefined,
  });

  const tierTypes = [
    { label: 'Flat Fee', value: 'FLAT_FEE' },
    { label: 'Fixed per hour rate', value: 'PER_HOUR' },
    { label: 'Tier Rate', value: 'TIER_RATE' },
  ];

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await updateASOnePlan(plan.id, plan);
      setIsSubmitting(false);
      closeModal();
      onSuccess();
    } catch (error) {
      setIsSubmitting(false);
      toast.error(EErrorMessages.DEFAULT);
    }
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Edit plan</h2>

      <div className="input-wrapper">
        <div className="label">Plan title</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlan({ ...plan, name: e.target.value })}
          label="Plan title"
          value={plan.name}
        />
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Monthly price per license</div>
          <H30pxCurrencyInput
            onChange={(_, v2) => {
              setPlan({ ...plan, price: v2 });
            }}
            label="$ 0"
            value={plan.price}
          />
        </div>

        {/* <div className="switch-wrapper items-center">*/}
        {/*  <div className="label">Reporter Studio Pro</div>*/}
        {/*  <Switch className="switch" checked={plan.rsPro} onChange={() => setPlan({ ...plan, rsPro: !plan.rsPro })} />*/}
        {/* </div>*/}
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Realtime / ASR rate</div>

          <Select
            name="asrRateType"
            label="Type"
            value={plan.asrRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, asrRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.asrRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, asrRate: v2 });
              }}
              label="$ 0"
              value={plan.asrRate}
            />
          ) : null}
        </div>
        <div className="input-wrapper">
          <div className="label">Post rate</div>
          <Select
            name="postRateType"
            label="Type"
            value={plan.postRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, postRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.postRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, postRate: v2 });
              }}
              label="$ 0"
              value={plan.postRate}
            />
          ) : null}
        </div>
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Reporter Unlock rate</div>
          <Select
            name="reporterUnlockRateType"
            label="Type"
            value={plan.reporterUnlockRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, reporterUnlockRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.reporterUnlockRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, reporterUnlockRate: v2 });
              }}
              label="$ 0"
              value={plan.reporterUnlockRate}
            />
          ) : null}
        </div>
        <div className="input-wrapper">
          <div className="label">Full Unlock rate</div>
          <Select
            name="fullUnlockRateType"
            label="Type"
            value={plan.fullUnlockRateType || 'FLAT_FEE'}
            onChange={(_, value) => setPlan({ ...plan, fullUnlockRateType: value })}
            options={tierTypes}
            required={false}
          />
          {plan.fullUnlockRateType !== 'TIER_RATE' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setPlan({ ...plan, fullUnlockRate: v2 });
              }}
              label="$ 0"
              value={plan.fullUnlockRate}
            />
          ) : null}
        </div>
      </div>

      <div className="input-wrapper">
        <div className="label">Free trial days</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, trialPeriodDays: parseInt(e.target.value, 10) || 0 })
          }
          label="Number of free trial days"
          value={plan.trialPeriodDays}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Free Seats</div>
        <H30pxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, freeSeats: parseInt(e.target.value, 10) || 0 })
          }
          label="Number of free seats in this plan"
          value={plan.freeSeats}
        />
      </div>

      <div className="public">
        <div className="label">Public plan</div>
        <Switch checked={plan.public} onChange={() => setPlan({ ...plan, public: !plan.public })} />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </Wrapper>
  );
};
