import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { useAPI } from '@hooks/useAPI';

import { AdminService } from 'app/API';
import { Select } from '@components/form-elements/Select';
import { H30pxCurrencyInput } from '@components/form-elements/CurrencyInput/variations/H30pxCurrencyInput';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 400px;

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .name {
      color: #00122d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      padding: 0 0 20px;
    }

    input {
      height: 30px;
    }
  }

  .flex-row {
    margin: 0 0 20px;

    .input-wrapper,
    .switch-wrapper {
      max-width: 166px;
    }

    .form-select {
      min-width: 166px;
    }

    .input-wrapper {
      .input,
      .textarea {
        margin: 0;
      }
    }
  }

  .card,
  .rate {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  billingAccountId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const tierTypes = [
  { label: 'Disallowed', value: 'DISALLOWED' },
  { label: 'Flat Fee', value: 'FLAT_FEE' },
  { label: 'Fixed per hour rate', value: 'PER_HOUR' },
  { label: 'Tier Rate', value: 'TIER_RATE' },
];

export const AdminBillingAccountRatesEditorModal = ({ billingAccountId, onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const { call } = useAPI();
  const [rates, setRates] = useState({
    realTimeRateType: 'FLAT_FEE',
    realTimeRate: '0',
    postProcessingRateType: 'FLAT_FEE',
    postProcessingRate: '0',
    partialUnlockRateType: 'FLAT_FEE',
    partialUnlockRate: '0',
    fullUnlockRateType: 'FLAT_FEE',
    fullUnlockRate: '0',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const billingAccount = await call(AdminService.getBillingAccount({ baId: billingAccountId }));
      setBillingAccount(billingAccount);
      setRates(billingAccount.billingConfiguration);
      setIsLoading(false);
    })();
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);
    await call(
      AdminService.setBillingAccountRates({
        baId: billingAccountId,
        requestBody: {
          realTimeRateType: rates.realTimeRateType,
          realTimeRate: Number(rates.realTimeRate),
          postProcessingRateType: rates.postProcessingRateType,
          postProcessingRate: Number(rates.postProcessingRate),
          partialUnlockRateType: rates.partialUnlockRateType,
          partialUnlockRate: Number(rates.partialUnlockRate),
          fullUnlockRateType: rates.fullUnlockRateType,
          fullUnlockRate: Number(rates.fullUnlockRate),
        },
      }),
    );
    closeModal();
    onSuccess();
  };

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>AutoScript Rates Editor</h2>

      <div className="input-wrapper">
        <div className="label">Billing Account</div>
        <div className="name">{billingAccount?.name}</div>
      </div>

      <div className="input-wrapper">
        <div className="label">Organization</div>
        <div className="name">{billingAccount?.organization?.name}</div>
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Post Processing Rate</div>
          <Select
            name="postProcessingRateType"
            label="Type"
            value={rates.postProcessingRateType || 'FLAT_FEE'}
            onChange={(_, value) => setRates({ ...rates, postProcessingRateType: value })}
            options={tierTypes}
            required={false}
          />
          {rates.postProcessingRateType !== 'TIER_RATE' && rates.postProcessingRateType !== 'DISALLOWED' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setRates({ ...rates, postProcessingRate: v2 });
              }}
              label="$ 0"
              value={rates.postProcessingRate}
            />
          ) : null}
        </div>
        <div className="input-wrapper">
          <div className="label">Real-Time Rate</div>
          <Select
            name="realTimeRateType"
            label="Type"
            value={rates.realTimeRateType || 'FLAT_FEE'}
            onChange={(_, value) => setRates({ ...rates, realTimeRateType: value })}
            options={tierTypes}
            required={false}
          />
          {rates.realTimeRateType !== 'TIER_RATE' && rates.realTimeRateType !== 'DISALLOWED' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setRates({ ...rates, realTimeRate: v2 });
              }}
              label="$ 0"
              value={rates.realTimeRate}
            />
          ) : null}
        </div>
      </div>

      <div className="flex-row flex gap-2 items-top">
        <div className="input-wrapper">
          <div className="label">Partial Unlock Rate</div>
          <Select
            name="partialUnlockRateType"
            label="Type"
            value={rates.partialUnlockRateType || 'FLAT_FEE'}
            onChange={(_, value) => setRates({ ...rates, partialUnlockRateType: value })}
            options={tierTypes}
            required={false}
          />
          {rates.partialUnlockRateType !== 'TIER_RATE' && rates.partialUnlockRateType !== 'DISALLOWED' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setRates({ ...rates, partialUnlockRate: v2 });
              }}
              label="$ 0"
              value={rates.partialUnlockRate}
            />
          ) : null}
        </div>
        <div className="input-wrapper">
          <div className="label">Full Unlock Rate</div>
          <Select
            name="fullUnlockRateType"
            label="Type"
            value={rates.fullUnlockRateType || 'FLAT_FEE'}
            onChange={(_, value) => setRates({ ...rates, fullUnlockRateType: value })}
            options={tierTypes}
            required={false}
          />
          {rates.fullUnlockRateType !== 'TIER_RATE' && rates.fullUnlockRateType !== 'DISALLOWED' ? (
            <H30pxCurrencyInput
              onChange={(_, v2) => {
                setRates({ ...rates, fullUnlockRate: v2 });
              }}
              label="$ 0"
              value={rates.fullUnlockRate}
            />
          ) : null}
        </div>
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
};
