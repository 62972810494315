import { useState } from 'react';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';

import { useModalContext } from '@providers/ModalProvider';
import { getHourPrice } from '@helpers/getHourPrice';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TSubscription } from 'app/types/entities/TSubscription';

import { Dots } from '@components/icons/Dots';
import { DropDown } from '@components/form-elements/DropDown';

const Wrapper = styled.div`
  width: 210px;
  min-height: 220px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .as-one {
    color: #858dbd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
  }

  .content {
    padding: 20px 20px 7px;
  }

  .plan-name {
    color: #7cb1fe;
    font-size: 35.119px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: -5px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .buttonContainer {
    height: 40px;
    width: calc(100% - 40px);
    margin: 0 20px;
    display: block;
    bottom: 0;
    position: absolute;
    border-top: 1px solid #d5ddf2;

    button {
      border-radius: 10px;
      padding: 0;
      height: 36px;
      width: 100%;
    }
  }

  .price-active {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: flex;
    padding: 0 20px 5px;
    gap: 10px;
    justify-content: space-evenly;

    .divider {
      height: 41px;
      border-right: 1px solid #858dbd;
    }
  }
`;

const DotsButton = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;

  :hover {
    & svg {
      circle {
        fill: #858dbd;
      }
    }
  }
`;

type TProps = {
  className?: string;
  reload: () => void;
  plan: TSubscriptionProduct;
  subscription: TSubscription;
};

export const TrialingCard = ({ className = '', plan, subscription, reload }: TProps) => {
  const { openModal, closeModal } = useModalContext();
  const [showDropDown, setShowDropDown] = useState(false);
  const licensesCount = subscription?.licenses?.length || 1;

  const planName = plan?.name.replace('AutoScriptOne ', '');
  const price = getHourPrice(plan?.capacityInSeconds, Number(plan?.price) * licensesCount, true);
  const daysDiff = differenceInDays(new Date(subscription.freeTrialEndDate ?? 0), new Date());

  const dropDownOptions: {
    title: string;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  }[] = [];

  dropDownOptions.push({
    title: 'Cancel',
    onClick: (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      openModal(ModalNamesEnum.AdminConfirmSubscriptionCancellation, {
        subscriptionId: subscription.id,
        planName: plan.name,
        onSuccess: () => {
          reload();
          closeModal();
        },
      });
    },
  });

  const renderOptions = () => {
    return (
      <>
        <DotsButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(true);
          }}
        >
          <Dots />
        </DotsButton>
        {showDropDown ? <DropDown close={() => setShowDropDown(false)} options={dropDownOptions} /> : null}
      </>
    );
  };

  const renderPriceActive = () => {
    const unassignedCount = subscription?.licenses.filter((lic) => lic.assigneeId === null).length;
    const assignedCount = subscription?.licenses.filter((lic) => lic.assigneeId !== null).length;
    return (
      <div className="price-active">
        <div className="left">
          <div>Free trial</div>
          {daysDiff > 0 ? <div>{daysDiff + 1} days</div> : <div>{price}/seat</div>}
        </div>
        <div className="divider"></div>
        <div className="right">
          <div>Active</div>
          <div>
            {assignedCount}/{assignedCount + unassignedCount}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="as-one">
          {plan.subscriptionProductCategory.replace('ReporterStudioPro', 'Reporter Studio Pro')}
        </h2>
        <h2 className="plan-name">{planName}</h2>
        {dropDownOptions.length > 0 && renderOptions()}
      </div>

      {renderPriceActive()}
    </Wrapper>
  );
};
